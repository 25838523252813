module config {
    export class appNavSecure {
        $inject = ["$rootScope", "$location", '$transitions', '$q'];
        constructor(private $rootScope: interfaces.applicationcore.IRootScope, private $location: ng.ILocationService, private $transitions: ng.ui.core.ITransition,
            private $q: ng.IQService) {

            $transitions.onStart({ to: "auth.**" }, (trans: ng.ui.core.ITransition) => {
                var $state = trans.router.stateService;
                var UserService: interfaces.applicationcore.IUserAccountService = trans.injector().get('userAccountService');
                this.$rootScope.IsResolving = true;

                this.$rootScope.previousPath = this.$location.url();

                return new Promise<boolean>(resolve =>
                    UserService.hasAccess(trans.targetState()).catch((rejectUrl) => {
                        if (rejectUrl.status === -1)
                            return ($state.target("connectionerror"));
                        else
                            return ($state.target("unauthorised", { url: rejectUrl }));
                    }).then((result: boolean) => {
                        this.$rootScope.IsResolving = false;
                        resolve(result);
                    }, (result) => {
                        this.$rootScope.IsResolving = false;
                        return ($state.target("unauthorised", { url: result }));
                    })
                );                
            });
        };
    };
    angular.module("app").run(config.appNavSecure);
}